import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./cards.css";
import cardimg from "../../assets/images/API.jpeg";
import cardimg2 from "../../assets/images/CRM.jpeg";
import cardimg3 from "../../assets/images/QA.jpeg";
import cardimg4 from "../../assets/images/webdev.jpeg";
import cardimg5 from "../../assets/images/marketing.jpeg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const CardItem = ({ card, onContactClick, isRightAligned, ref }) => {
	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.2,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<motion.div
			className={`card-img-wrapper ${
				isRightAligned ? "image-right" : ""
			}`}
			ref={inViewRef}
			initial="hidden"
			animate={controls}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
			transition={{ delay: 0.2, duration: 1.1 }}>
			<Card className="card">
				<CardActionArea>
					<CardContent>
						<Typography
							className="card-title"
							gutterBottom
							variant="h3"
							component="div">
							{card.title}
						</Typography>
						<Typography
							className="card-description"
							variant="body1"
							color="text.secondary">
							{card.description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button
						className="contact-button"
						size="large"
						color="primary"
						onClick={onContactClick}>
						Contact Us
					</Button>
				</CardActions>
			</Card>
			<img
				className="card-img"
				src={card.img}
				alt={`Card image for ${card.title}`}
			/>
		</motion.div>
	);
};

export default function MultiActionAreaCard({ onContactClick, cardRefs }) {
	const navigate = useNavigate();

	const cards = [
		{
			id: 1,
			title: "CRM Consulting",
			description:
				"Unlock the full potential of your business with our CRM consulting services. Our team of experts specializes in tailoring CRM solutions to suit your unique needs and goals. From implementation to optimization, we provide comprehensive guidance every step of the way. Let us streamline your processes, enhance customer relationships, and drive growth. Partner with us to harness the power of CRM and propel your business forward.",
			img: cardimg2,
		},
		{
			id: 2,
			title: "API Integrations",
			description:
				"Empower your business with seamless API integration services tailored to your needs. Our expert team specializes in bridging the gap between your systems and external platforms, ensuring smooth data exchange and enhanced functionality. From initial setup to ongoing support, we’re dedicated to optimizing your digital ecosystem. Elevate your operations, streamline processes, and unlock new possibilities with our API integration solutions.",
			img: cardimg,
		},

		{
			id: 3,
			title: "Web Development",
			description:
				"We specialize in delivering top-notch web development services tailored to meet the unique needs of our clients. Our team of skilled developers is proficient in a variety of programming languages and frameworks, ensuring that we can bring your vision to life effectively. From creating dynamic and user-friendly websites to developing complex web applications, we handle projects of all sizes with precision and expertise. With a focus on responsive design and seamless functionality, we guarantee that your website will not only look stunning but also perform flawlessly across all devices. Additionally, we offer ongoing support and maintenance to ensure that your web presence remains optimized and up-to-date. Trust us to elevate your online presence and propel your business forward in the digital landscape.",
			img: cardimg4,
		},
		{
			id: 4,
			title: "Quality Assurance Services",
			description:
				"Elevate your project’s quality with our freelance quality assurance services. Our meticulous testing procedures ensure your software meets the highest standards before launch. With expertise in various industries and technologies, we offer tailored QA solutions to fit your specific needs. Trust us to enhance user satisfaction and minimize errors, leaving you with a polished and reliable product.",
			img: cardimg3,
		},
		{
			id: 5,
			title: "Digital Marketing",
			description:
				"Elevate your digital footprint with our comprehensive suite of Digital Marketing Services tailored to boost your online presence and drive measurable results. Harness the power of targeted email marketing campaigns to engage your audience and nurture leads effectively. Our robust tracking and analytics solutions provide invaluable insights into campaign performance, empowering informed decision-making for optimal results. From building a strong online presence to establishing a prominent local footprint, our strategies are designed to amplify your brand visibility across digital channels. Maximize your reach and engagement with strategic social media advertising campaigns tailored to your target audience. Leverage the potential of search engine marketing to ensure your brand stands out amidst the digital clutter. With our detailed reporting mechanisms, track the effectiveness of your campaigns and refine your strategies for continuous improvement and success. Let us be your trusted partner in navigating the dynamic landscape of digital marketing, driving growth and unlocking new opportunities for your business.",
			img: cardimg5,
		},
	];

	return (
		<div className="card-container">
			{cards.map((card, index) => (
				<CardItem
					key={card.id}
					card={card}
					onContactClick={onContactClick}
					isRightAligned={index % 2 === 1}
					ref={cardRefs[index]}
				/>
			))}
		</div>
	);
}
