import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./webdevfeatures.css";

// Import the images
import HSSH from "../../../assets/images/HSSH.png";
import HSCRM from "../../../assets/images/HSCRM.png";
import HSMH from "../../../assets/images/HSMH.png";
import HSServH from "../../../assets/images/HSServH.png";

const Features = () => {
	const featuresData = [
		{
			image: HSCRM,
			title: "Web Design",
			text: "Craft visually stunning and user-friendly websites that embody your brand’s identity and engage your audience. Our designs ensure a compelling and efficient experience across all devices.",
		},
		{
			image: HSMH,
			title: "Custom Dashboards",
			text: " Build insightful, user-focused dashboards that simplify data visualization and enhance your decision-making process. Tailored to meet your business’s specific needs, our dashboards help you manage and interpret data effectively.",
		},
		{
			image: HSSH,
			title: "Web Applications",
			text: "Develop dynamic web applications that streamline processes and improve operational efficiency. Our solutions are designed to meet your unique requirements and are adaptable as your business evolves.",
		},
		{
			image: HSServH,
			title: "E-Commerce Solutions",
			text: "Create powerful online stores that boost sales and enhance the customer shopping experience. From product showcases to secure checkout systems, our e-commerce solutions are designed to drive conversions and support customer loyalty.",
		},
	];

	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<div id="features" className="text-center">
			<motion.div
				ref={inViewRef}
				initial="hidden"
				animate={controls}
				variants={{
					hidden: { opacity: 0, y: 100 },
					visible: { opacity: 1, y: 0 },
				}}
				transition={{ delay: 0.2, duration: 0.9 }}
				className="container">
				<Typography
					className="ourFeatures"
					variant="h3"
					component="h2"
					style={{ textAlign: "center", paddingTop: "24px" }}>
					Our Expertise
				</Typography>
				<div className="row">
					{featuresData.map((feature, index) => (
						<div key={index} className="feature-card-wrapper">
							<Card className="feature-card">
								<CardContent>
									<Typography
										className="feature-image-container"
										variant="h4"
										component="div">
										<img
											src={feature.image}
											alt={feature.title}
											className="feature-image"
										/>
									</Typography>
									<Typography
										className="feature-title"
										gutterBottom
										variant="h5"
										component="div">
										{feature.title}
									</Typography>
									<Typography
										className="feature-description"
										variant="body1"
										color="text.secondary">
										{feature.text}
									</Typography>
								</CardContent>
							</Card>
						</div>
					))}
				</div>
			</motion.div>
		</div>
	);
};

export default Features;
