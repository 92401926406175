import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../Home";
import { AnimatePresence } from "framer-motion";
import { Navbar } from "../Navbar";
import { Social } from "../../components/Social";
import { ContactRoute } from "../routes/ContactRoute";
import NotFound from "../../components/notFound/NotFound";
import Termsandprivacy from "../Terms";
import LandingPage from "../../components/landingPage/LandingPage";
import WebDevLandingPage from "../../pages/webdevlandingpage/webdevlandingpage";

export default function AnimateRoute() {
	const location = useLocation();

	return (
		<>
			<AnimatePresence>
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
					<Route path="/contact" element={<ContactRoute />} />
					<Route path="*" element={<NotFound />} />
					<Route
						path="/privacypolicy"
						element={<Termsandprivacy />}
					/>
					<Route path="/hubspot" element={<LandingPage />} />
					<Route path="/webdev" element={<WebDevLandingPage />} />
				</Routes>
			</AnimatePresence>
		</>
	);
}
