import React, { useState, forwardRef } from "react";
import "./Contact.css";
import { default as location } from "../assets/icons/location.png";
import { default as email } from "../assets/icons/mail.png";
import { default as phone } from "../assets/icons/phone-call.png";
import Map from "../components/map/Map";
import { motion } from "framer-motion";
import ReactGA from "react-ga";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const Contact = forwardRef((props, ref) => {
	const [info, setInfo] = useState({
		fname: "",
		lname: "",
		email: "",
		number: "",
		message: "",
	});

	const isValidEmail = (email) => {
		return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
	};

	const isValidMobile = (number) => {
		return /^[1-9]\d{9}$/.test(number);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setInfo((prev) => ({ ...prev, [name]: value }));

		if (name === "number") {
			setInfo((prev) => ({
				...prev,
				number: value.replace(/\D/g, ""),
			}));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (
			!info.fname ||
			!info.email ||
			!info.message ||
			!info.lname ||
			!info.number
		) {
			toast.error("All fields are required");
			return;
		}

		if (!isValidMobile(info.number)) {
			toast.error("Please enter a valid 10-digit mobile number.");
			return;
		}

		if (!isValidEmail(info.email)) {
			toast.error("Please enter a valid email address.");
			return;
		}

		ReactGA.event({
			category: "Contact Form",
			action: "Submit",
			label: "Contact Us",
		});

		const emailData = {
			fname: info.fname,
			lname: info.lname,
			email: info.email,
			number: info.number,
			message: info.message,
		};

		try {
			const backendUrl = process.env.REACT_APP_BACKEND_URL;
			await axios.post(
				`https://y-iota-blue.vercel.app/api/email/send`,
				emailData
			);
			toast.success("Your message has been sent successfully!");
		} catch (error) {
			console.error("Failed to send email", error);
			toast.error("Failed to send your message. Please try again.");
		}

		setInfo({
			fname: "",
			lname: "",
			email: "",
			number: "",
			message: "",
		});
	};

	return (
		<motion.section
			ref={ref}
			className="contact"
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.2 }}
			transition={{ delay: 0.2, duration: 1.1 }}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}>
			<Toaster position="top-right" reverseOrder={false} />
			<h2 className="title contact-us">Contact Us</h2>

			<div className="contact-Container">
				<div className="boxOne">
					<p className="reachUs">Reach us:</p>
					<div className="boxInner">
						<span className="iconSpan">
							<img src={location} alt="" width="100%" />{" "}
						</span>
						<p>Canton, Ohio 44708</p>
					</div>
					<div className="boxInner">
						<span className="iconSpan">
							<img src={email} alt="" width="100%" />{" "}
						</span>
						<p>hello@kleinbergconsulting.com</p>
					</div>
					<div className="boxInner">
						<span className="iconSpan">
							<img src={phone} alt="" width="100%" />{" "}
						</span>
						<p className="phone">
							<a className="phone" href="tel:+3303538811">
								+330-353-8811
							</a>
						</p>
					</div>
				</div>
				<Map />
				<div className="boxThree formBox">
					<h3 className="sendMsg">Send us a message</h3>
					<form className="form" onSubmit={handleSubmit}>
						<div className="nestedBox">
							<div className="box1">
								<input
									type="text"
									placeholder="First Name"
									className="firstName"
									name="fname"
									value={info.fname}
									onChange={handleChange}
								/>
							</div>
							<div>
								<input
									type="text"
									placeholder="Last Name"
									className="lastName"
									name="lname"
									value={info.lname}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="nestedBox">
							<div>
								<input
									type="email"
									placeholder="Enter your email"
									className="email"
									required
									name="email"
									value={info.email}
									onChange={handleChange}
								/>
							</div>
							<div>
								<input
									type="text"
									placeholder="Phone Number"
									className="inputphone"
									name="number"
									maxLength={10}
									value={info.number}
									onChange={handleChange}
								/>
							</div>
						</div>
						<EditPost
							message={info.message}
							handleChange={handleChange}
						/>
						<button className="sendBtn">Send a message</button>
					</form>
				</div>
			</div>
		</motion.section>
	);
});

function EditPost({ message, handleChange }) {
	return (
		<textarea
			className="textarea"
			name="message"
			value={message}
			onChange={handleChange}
			placeholder="Write your message here"
			rows={9}
			cols={40}
		/>
	);
}
