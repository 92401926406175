import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./features.css";

// Import the images
import HSSH from "../../assets/images/HSSH.png";
import HSCRM from "../../assets/images/HSCRM.png";
import HSMH from "../../assets/images/HSMH.png";
import HSServH from "../../assets/images/HSServH.png";

const Features = () => {
	const featuresData = [
		{
			image: HSCRM,
			title: "HubSpot CRM",
			text: "Optimize your customer relationship management with customized solutions that enhance sales and improve customer interactions.",
		},
		{
			image: HSMH,
			title: "HubSpot Marketing Hub",
			text: "Streamline your marketing efforts with personalized campaigns, automated workflows, and comprehensive analytics to drive growth.",
		},
		{
			image: HSSH,
			title: "HubSpot Sales Hub",
			text: "Boost your sales efficiency with tailored setups that automate sales processes, track performance, and enhance customer engagement.",
		},
		{
			image: HSServH,
			title: "HubSpot Service Hub",
			text: "Elevate your customer support with integrated helpdesk solutions that improve response times and customer satisfaction.",
		},
	];

	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<div id="features" className="text-center">
			<motion.div
				ref={inViewRef}
				initial="hidden"
				animate={controls}
				variants={{
					hidden: { opacity: 0, y: 100 },
					visible: { opacity: 1, y: 0 },
				}}
				transition={{ delay: 0.2, duration: 0.9 }}
				className="container">
				<Typography
					className="ourFeatures"
					variant="h3"
					component="h2"
					style={{ textAlign: "center", paddingTop: "24px" }}>
					Our Expertise
				</Typography>
				<div className="row">
					{featuresData.map((feature, index) => (
						<div key={index} className="feature-card-wrapper">
							<Card className="feature-card">
								<CardContent>
									<Typography
										className="feature-image-container"
										variant="h4"
										component="div">
										<img
											src={feature.image}
											alt={feature.title}
											className="feature-image"
										/>
									</Typography>
									<Typography
										className="feature-title"
										gutterBottom
										variant="h5"
										component="div">
										{feature.title}
									</Typography>
									<Typography
										className="feature-description"
										variant="body1"
										color="text.secondary">
										{feature.text}
									</Typography>
								</CardContent>
							</Card>
						</div>
					))}
				</div>
			</motion.div>
		</div>
	);
};

export default Features;
