import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./about.css";
import aboutusimage from "../../assets/images/AdobeStock_140443759.jpeg";

const About = () => {
	const aboutData = {
		paragraph:
			"At Kleinberg Consulting, we specialize in transforming businesses through expert HubSpot Marketing Hub consultation and development services. Our team of certified professionals, headquartered in Northeast Ohio, is dedicated to delivering tailored solutions that streamline your marketing operations, enhance productivity, and drive growth. With extensive expertise in HubSpot CRM, HubSpot Email Marketing, HubSpot Social Media Management, HubSpot Analytics, and more, we partner with you to turn your vision into reality and achieve outstanding results.",
		Why: [
			"<strong>Expertise:</strong> Certified Zoho professionals with extensive experience.",
			"<strong>Customization:</strong> Tailored solutions to fit your unique business needs.",
			"<strong>Support:</strong> Ongoing support and maintenance for seamless operations.",
			"<strong>Results-Driven:</strong> Focused on achieving tangible results and ROI.",
		],
	};

	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.125,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<motion.div
			ref={inViewRef}
			initial="hidden"
			animate={controls}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
			transition={{ delay: 0.2, duration: 0.9 }}
			className="wide-card">
			<Card className="wide-card-content">
				<div className="card-inner">
					<div className="card-img-aboutus">
						<img
							src={aboutusimage}
							alt="About Us"
							className="img-responsive"
						/>
					</div>
					<CardContent className="card-content">
						<Typography
							className="card-title"
							gutterBottom
							variant="h3"
							component="div">
							About Us
						</Typography>
						<Typography
							className="card-description"
							variant="body1"
							color="text.secondary">
							{aboutData.paragraph}
						</Typography>
						<Typography
							className="card-title title-bottom"
							gutterBottom
							variant="h4"
							component="div">
							Why Choose Us?
						</Typography>
						{aboutData.Why.map((reason, index) => (
							<Typography
								key={index}
								className="card-description"
								variant="body1"
								color="text.secondary"
								dangerouslySetInnerHTML={{ __html: reason }} // Use dangerouslySetInnerHTML to render the HTML
							></Typography>
						))}
					</CardContent>
				</div>
			</Card>
		</motion.div>
	);
};

export default About;
