import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./webdevabout.css";
import aboutusimage from "../../../assets/images/AdobeStock_140443759.jpeg";

const About = () => {
	const aboutData = {
		paragraph:
			"At Kleinberg Consulting, we specialize in transforming businesses through custom web development solutions. Our team of skilled developers and designers is dedicated to crafting unique digital experiences that enhance your brand, improve user engagement, and drive growth. Whether you need a visually stunning website, an innovative web app, or a tailored dashboard, we work closely with you to bring your vision to life with precision and creativity.",
		Why: [
			"<strong>Expertise:</strong> A team of experienced web developers and designers with a deep knowledge of the latest technologies and best practices.",
			"<strong>Customization:</strong> Solutions designed to meet your specific business goals and cater to your audience’s needs.",
			"<strong>Support:</strong> Ongoing support and maintenance to ensure your website and applications run efficiently.",
			"<strong>Results-Driven:</strong> Focused on creating high-performance digital tools that deliver measurable results and drive ROI.",
			"<strong>Scalability:</strong> Solutions that are built to grow with your business, providing the flexibility to adapt and expand as your needs change.",
		],
	};

	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.125,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<motion.div
			ref={inViewRef}
			initial="hidden"
			animate={controls}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
			transition={{ delay: 0.2, duration: 0.9 }}
			className="wide-card">
			<Card className="wide-card-content">
				<div className="card-inner">
					<div className="card-img-aboutus">
						<img
							src={aboutusimage}
							alt="About Us"
							className="img-responsive"
						/>
					</div>
					<CardContent className="card-content">
						<Typography
							className="card-title"
							gutterBottom
							variant="h3"
							component="div">
							About Us
						</Typography>
						<Typography
							className="card-description"
							variant="body1"
							color="text.secondary">
							{aboutData.paragraph}
						</Typography>
						<Typography
							className="card-title title-bottom"
							gutterBottom
							variant="h4"
							component="div">
							Why Choose Us?
						</Typography>
						{aboutData.Why.map((reason, index) => (
							<Typography
								key={index}
								className="card-description"
								variant="body1"
								color="text.secondary"
								dangerouslySetInnerHTML={{ __html: reason }} // Use dangerouslySetInnerHTML to render the HTML
							></Typography>
						))}
					</CardContent>
				</div>
			</Card>
		</motion.div>
	);
};

export default About;
