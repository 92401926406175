import React from "react";
import "./Footer.css";

const Footer = ({ scrollToCard }) => {
	return (
		<footer className="footerContainer">
			<div className="footer">
				<div className="grid-item1">
					<h3 className="header about-us">About Us</h3>
					<p className="footer-services">
						With over a decade of experience in the industry,{" "}
						<span className="companyName">
							Kleinberg Consulting
						</span>{" "}
						is a trusted name known for delivering high-quality
						projects on time and within budget.
					</p>
				</div>
				{/* 
				<div className="grid-item2">
					<h3
						className="header footer-services"
						onClick={() => scrollToCard(0)}>
						Services
					</h3>
					<p
						className="footer-services"
						onClick={() => scrollToCard(0)}>
						API Integrations
					</p>
					<p
						className="footer-services"
						onClick={() => scrollToCard(1)}>
						CRM Consulting
					</p>
					<p
						className="footer-services"
						onClick={() => scrollToCard(2)}>
						Quality Assurance Services
					</p>
					<p
						className="footer-services"
						onClick={() => scrollToCard(3)}>
						Web Development
					</p>
					<p
						className="footer-services"
						onClick={() => scrollToCard(4)}>
						Digital Marketing
					</p>
				</div> */}

				<div className="grid-item3">
					<h3 className="header">Hours of Operation</h3>
					<p>Monday — Friday</p>
					<p>9am — 6pm</p>
				</div>
			</div>
			<div className="copyRight">
				<p>
					©{" "}
					<span className="companyName">
						Kleinberg Consulting, <span className="llc">LLC</span>
					</span>{" "}
					All Rights Reserved.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
