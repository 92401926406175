import React, { useEffect } from "react";

const ZohoChatWidget = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.id = "zsiqscript";
		script.defer = true;
		script.src = "https://salesiq.zohopublic.com/widget";

		script.onload = () => {
			const $zoho = window.$zoho || {};
			$zoho.salesiq = $zoho.salesiq || {
				widgetcode:
					"siqe9eb79343c97f503ee0e43ebca191146c029d4ca2d26da091591252419cb9513",
				values: {},
				ready: function () {},
			};
		};

		document.getElementsByTagName("head")[0].appendChild(script);

		return () => {
			if (script.parentNode) {
				script.parentNode.removeChild(script);
			}
		};
	}, []);

	return null; // This component does not render anything visible
};

export default ZohoChatWidget;
