import React, { useState, useEffect } from "react";
import AnimateRoute from "./pages/animateRoute/AnimateRoutes";
import { Loader } from "./components/Loader";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast"; // Import Toaster

const TRACKING_ID = "G-NXX76PX39N"; // YOUR_TRACKING_ID

export default function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Initialize Google Analytics
		ReactGA.initialize(TRACKING_ID);

		// Initialize Google Tag Manager
		const tagManagerArgs = {
			gtmId: "AW-16595114253",
		};
		TagManager.initialize(tagManagerArgs);
	}, []); // Empty dependency array ensures this runs only once

	return (
		<div>
			<Toaster position="top-right" /> {/* Add the Toaster component */}
			{loading ? (
				<Loader loading={loading} setLoading={setLoading} />
			) : (
				<AnimateRoute />
			)}
		</div>
	);
}
