import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./testimonials.css"; // Import the custom CSS

const Testimonials = () => {
	const testimonialsData = [
		{
			icon: "fa fa-briefcase",
			name: "Consulting and Accounting",
			text: "HubSpot's suite of applications empowers consulting and accounting firms to enhance operational efficiency. From CRM for precise client management to Marketing Hub for automating campaigns, we enable you to focus on delivering outstanding service while streamlining your processes.",
		},
		{
			icon: "fa fa-tree",
			name: "Outdoor Services",
			text: "Landscaping, lawn care, and property management businesses benefit from HubSpot’s robust tools like CRM for client interactions, Sales Hub for organizing sales activities, and Service Hub for superior customer support. Manage projects seamlessly and optimize scheduling to elevate your service quality.",
		},
		{
			icon: "fa fa-users",
			name: "Customer-Focused",
			text: "Call centers, customer service teams, and sales departments rely on HubSpot CRM for effective lead management, Service Hub for responsive support, and Marketing Hub for crafting targeted campaigns. Boost customer satisfaction through personalized engagement and efficient workflows.",
		},
		{
			icon: "fa fa-shopping-cart",
			name: "Retail",
			text: "Retailers and consumer goods companies leverage HubSpot CMS for building online storefronts, CRM for deepening customer relationships, and Sales Hub for tracking sales pipelines. From inventory management to multichannel sales, HubSpot equips your business to thrive in a dynamic market.",
		},
		{
			icon: "fa fa-gavel",
			name: "Legal and Compliance",
			text: "Law firms and legal departments streamline their operations with HubSpot CRM for managing client relations, Marketing Hub for executing legal marketing strategies, and CMS for developing custom applications. Ensure compliance and efficiency with solutions tailored to legal practices.",
		},
		{
			icon: "fa fa-heartbeat",
			name: "Healthcare and Wellness",
			text: "Healthcare providers, wellness centers, and medical practices rely on HubSpot’s CRM for patient management, Marketing Hub for health-focused outreach, and Service Hub for patient support. Simplify appointment scheduling, patient communication, and care delivery to boost patient satisfaction and operational efficiency.",
		},
		{
			icon: "fa fa-graduation-cap",
			name: "Education and eLearning",
			text: "Educational institutions and eLearning platforms utilize HubSpot’s tools such as CRM for student relationship management, Marketing Hub for outreach campaigns, and CMS for building custom online portals. From student enrollment to course delivery, HubSpot helps you enhance engagement and streamline academic processes.",
		},
		{
			icon: "fa fa-hand-holding-heart",
			name: "Nonprofits",
			text: "Nonprofit organizations and professional associations use HubSpot CRM for effective donor and member management, Marketing Hub for driving fundraising campaigns, and CMS for creating custom member portals. Boost engagement, increase donations, and efficiently manage your operations to further your mission.",
		},
		{
			icon: "fa fa-building",
			name: "Real Estate and Property Management",
			text: "Real estate agencies and property management companies utilize HubSpot CRM for client and property management, Marketing Hub for promoting property listings, and Sales Hub for overseeing deals. Optimize lead generation, sales processes, and client communications to drive growth in your business.",
		},
	];

	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<div id="testimonials">
			<motion.div
				ref={inViewRef}
				initial="hidden"
				animate={controls}
				variants={{
					hidden: { opacity: 0, y: 100 },
					visible: { opacity: 1, y: 0 },
				}}
				transition={{ delay: 0.2, duration: 0.9 }}
				className="container">
				<Typography
					className="ourServices"
					variant="h3"
					component="h2"
					style={{ textAlign: "center", paddingTop: "24px" }}>
					Industries We Serve
				</Typography>
				<div className="row">
					{testimonialsData.map((testimonial, index) => (
						<Card
							key={index}
							className="testimonial-card"
							sx={{ maxWidth: 345 }}>
							<CardContent className="cardc0ntent">
								<div className="testimonial-icon">
									<i className={testimonial.icon}></i>
								</div>
								<Typography
									className="testimonial-name"
									gutterBottom
									variant="h5"
									component="div">
									{testimonial.name}
								</Typography>
								<Typography
									className="testimonial-text"
									variant="body1"
									color="text.secondary">
									{testimonial.text}
								</Typography>
							</CardContent>
						</Card>
					))}
				</div>
			</motion.div>
		</div>
	);
};

export default Testimonials;
