import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./servicestwo.css"; // Import the custom CSS

const Servicestwo = () => {
	const servicesData = [
		{
			icon: "fa fa-cogs", // Updated icon for CRM customization
			name: "HubSpot CRM Customization",
			text: "Transform your customer relationship management with tailored HubSpot CRM solutions. We design and implement bespoke workflows, automate essential processes, and integrate third-party applications to enhance sales performance and refine customer interactions.",
		},
		{
			icon: "fa fa-bullhorn", // Updated icon for Marketing Automation
			name: "HubSpot Marketing Automation",
			text: "Elevate your marketing strategy with HubSpot Marketing Hub. We craft bespoke marketing campaigns, establish advanced automated workflows, and provide comprehensive analytics to drive expansion and maximize engagement.",
		},
		{
			icon: "fa fa-exchange-alt", // Updated icon for Data Integration
			name: "HubSpot Data Integration",
			text: "Achieve efficient data connectivity with HubSpot Data Integration. We harmonize data flow between diverse systems, reduce inconsistencies, and deliver a centralized data source to support strategic decision-making.",
		},
		{
			icon: "fa fa-chart-line", // Updated icon for Sales Enhancement
			name: "HubSpot Sales Enhancement",
			text: "Boost your sales efficiency with HubSpot Sales Hub. Our specialists fine-tune sales pipelines, automate key sales activities, and equip you with powerful tools to monitor performance and enhance client relationships.",
		},
		{
			icon: "fa fa-headset", // Updated icon for Customer Support Solutions
			name: "HubSpot Customer Support Solutions",
			text: "Revolutionize your customer support with HubSpot Service Hub. We deploy integrated helpdesk solutions, automate ticketing workflows, and offer detailed reporting to improve response times and elevate customer satisfaction.",
		},
		{
			icon: "fa fa-desktop", // Updated icon for CMS Design
			name: "HubSpot CMS Design",
			text: "Create engaging websites and landing pages with HubSpot CMS. We design customized templates, apply cutting-edge SEO practices, and integrate CRM data to optimize user experience and increase conversions.",
		},
		{
			icon: "fa fa-chart-pie", // Updated icon for Reporting & Analytics
			name: "HubSpot Reporting & Analytics",
			text: "Unlock the potential of your data with HubSpot Reporting & Analytics. We develop bespoke reports, set up interactive dashboards, and consolidate data from various sources to provide actionable insights and facilitate informed decisions.",
		},
		{
			icon: "fa fa-sync-alt", // Updated icon for Workflow Optimization
			name: "HubSpot Workflow Optimization",
			text: "Streamline your operations with HubSpot Workflow Optimization. We design and implement automated workflows for sales, marketing, and customer service, enhancing efficiency and ensuring high-quality interactions across all channels.",
		},
	];

	return (
		<div id="services" className="text-center">
			<div className="container">
				<Typography className="ourServices" variant="h3" component="h2">
					Services We Offer
				</Typography>
				<div className="row">
					{servicesData.map((service, index) => (
						<div key={index} className="service-card-wrapper">
							<Card className="service-card">
								<div className="service-card-inner">
									<div className="service-card-front">
										<CardContent>
											<Typography
												variant="h4"
												component="div"
												className="service-icon">
												<i className={service.icon}></i>
											</Typography>
											<Typography
												variant="h5"
												component="h3">
												{service.name}
											</Typography>
										</CardContent>
									</div>
									<div className="service-card-back">
										<CardContent>
											<Typography
												variant="body1"
												component="p"
												className="service-description">
												{service.text}
											</Typography>
										</CardContent>
									</div>
								</div>
							</Card>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Servicestwo;
