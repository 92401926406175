import React, { useState, forwardRef } from "react";
import "./webdevcontact.css";
import { default as location } from "../../../assets/icons/location.png";
import { default as email } from "../../../assets/icons/mail.png";
import { default as phone } from "../../../assets/icons/phone-call.png";
import Map from "../../map/Map";
import { motion } from "framer-motion";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const Contact = forwardRef((props, ref) => {
	const [contactData, setContactData] = useState({
		fname: "",
		lname: "",
		email: "",
		number: "",
		interestedIn: "",
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setContactData((prev) => ({ ...prev, [name]: value }));

		if (name === "number") {
			setContactData((prev) => ({
				...prev,
				number: value.replace(/\D/g, ""),
			}));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const { fname, lname, email, number, interestedIn } = contactData;

		if (!fname || !lname || !email || !number || !interestedIn) {
			toast.error("All fields are required.");
			return;
		}

		if (!/^[1-9]\d{9}$/.test(number)) {
			toast.error("Please enter a valid 10-digit mobile number.");
			return;
		}

		if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
			toast.error("Please enter a valid email address.");
			return;
		}

		try {
			await axios.post(
				`https://y-iota-blue.vercel.app/api/leads/addCompanyToCRM`,
				{
					First_Name: fname,
					Last_Name: lname,
					Email: email,
					Phone: number,
					Hubspot_Interested_In: interestedIn,
				}
			);
			toast.success(
				"Thank you for your message. We will contact you soon."
			);
			setContactData({
				fname: "",
				lname: "",
				email: "",
				number: "",
				interestedIn: "",
			});
		} catch (error) {
			toast.error("Failed to send your message. Please try again.");
		}
	};

	const options = [
		{ value: "PCAO", label: "Please choose an option" },
		{ value: "Hubspot CRM", label: "Hubspot CRM" },
		{ value: "Hubspot Marketing Hub", label: "Hubspot Marketing Hub" },
		{ value: "Hubspot Sales Hub", label: "Hubspot Sales Hub" },
		{ value: "Hubspot Service Hub", label: "Hubspot Service Hub" },
		{ value: "Other", label: "Other" },
	];

	return (
		<motion.section
			ref={ref}
			className="contact"
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.2 }}
			transition={{ delay: 0.2, duration: 1.1 }}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}>
			<Toaster position="top-right" />{" "}
			{/* Ensure position is set to top-right */}
			<h2 className="title contact-us">Contact Us</h2>
			<div className="contact-Container">
				<div className="boxOne">
					<p className="reachUs">Reach us:</p>
					<div className="boxInner">
						<span className="iconSpan">
							<img src={location} alt="" width="100%" />{" "}
						</span>
						<p>Canton, Ohio 44708</p>
					</div>
					<div className="boxInner">
						<span className="iconSpan">
							<img src={email} alt="" width="100%" />{" "}
						</span>
						<p>hello@kleinbergconsulting.com</p>
					</div>
					<div className="boxInner">
						<span className="iconSpan">
							<img src={phone} alt="" width="100%" />{" "}
						</span>
						<p className="phone">
							<a className="phone" href="tel:+3303538811">
								+330-353-8811
							</a>
						</p>
					</div>
				</div>
				<Map />
				<div className="boxThree formBox">
					<h3 className="sendMsg">Schedule a Free Consultation</h3>
					<form className="form" onSubmit={handleSubmit}>
						<div className="nestedBox">
							<div className="box1">
								<input
									type="text"
									placeholder="First Name"
									className="firstName"
									name="fname"
									value={contactData.fname}
									onChange={handleChange}
								/>
							</div>
							<div>
								<input
									type="text"
									placeholder="Last Name"
									className="lastName"
									name="lname"
									value={contactData.lname}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="nestedBox">
							<div>
								<input
									type="email"
									placeholder="Email"
									className="email"
									required
									name="email"
									value={contactData.email}
									onChange={handleChange}
								/>
							</div>
							<div>
								<input
									type="text"
									placeholder="Phone Number"
									className="inputphone"
									name="number"
									maxLength={10}
									value={contactData.number}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="hubspot-form-group3">
							<select
								name="interestedIn"
								className="form-control form-input"
								value={contactData.interestedIn}
								onChange={handleChange}>
								{options.map((option) => (
									<option
										key={option.value}
										value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</div>
						<button className="sendBtn">Have us reach out</button>
					</form>
				</div>
			</div>
		</motion.section>
	);
});
