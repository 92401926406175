import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./webdevtestimonials.css"; // Import the custom CSS

const Testimonials = () => {
	const testimonialsData = [
		{
			icon: "fa fa-briefcase", // Consulting and Accounting Firms
			name: "Consulting and Accounting Firms",
			text: "Optimized Online Presence and Efficiency. Consulting and accounting firms benefit from our custom web design and development solutions. We create professional websites and client portals that enhance your online presence and streamline client interactions, enabling you to focus on delivering exceptional service while improving operational efficiency.",
		},
		{
			icon: "fa fa-tree", // Outdoor Services
			name: "Outdoor Services",
			text: "Streamlined Project and Client Management. Landscaping, lawn care, and property management businesses rely on our custom web applications and dashboards. We build tools that optimize project management, improve client interactions, and enhance scheduling capabilities, helping you deliver top-notch service and manage your operations more effectively.",
		},
		{
			icon: "fa fa-users", // Customer-Focused Industries
			name: "Customer-Focused Industries",
			text: "Enhanced Customer Engagement and Functionality. Call centers, customer service teams, and sales departments leverage our web development services for improved customer engagement. From custom CRM integrations to tailored web applications, we design solutions that boost customer satisfaction, streamline workflows, and support efficient communication.",
		},
		{
			icon: "fa fa-shopping-cart", // Retail
			name: "Retail",
			text: "Dynamic E-Commerce and Omnichannel Solutions. Retailers and consumer goods companies benefit from our e-commerce development and web optimization services. We create robust online stores and integrate marketing tools to enhance your sales strategy, manage inventory, and offer a seamless shopping experience across multiple channels.",
		},
		{
			icon: "fa fa-gavel", // Legal and Compliance
			name: "Legal and Compliance",
			text: "Custom Solutions for Legal Efficiency. Law firms and legal departments utilize our web development expertise for creating secure, functional websites and client management systems. We design custom applications and optimize your online presence to ensure compliance, enhance client relations, and streamline legal processes.",
		},
		{
			icon: "fa fa-heartbeat", // Healthcare and Wellness
			name: "Healthcare and Wellness",
			text: "Improved Patient Interaction and Management. Healthcare providers and wellness centers rely on our custom web solutions to improve patient care and communication. We develop patient management systems, appointment scheduling tools, and health-focused web applications that enhance patient experiences and streamline operational tasks.",
		},
		{
			icon: "fa fa-graduation-cap", // Education and eLearning
			name: "Education and eLearning",
			text: "Engaging Online Learning Platforms. Educational institutions and eLearning platforms benefit from our custom CMS and web development services. We build engaging online portals, manage student relationships, and facilitate course delivery, helping you enhance student engagement and streamline educational processes.",
		},
		{
			icon: "fa fa-hand-holding-heart", // Nonprofits
			name: "Nonprofits",
			text: "Effective Donor and Member Engagement. Nonprofit organizations and associations utilize our web development services to manage donor relationships and create custom member portals. We design engaging websites and tools that support fundraising efforts, boost engagement, and streamline operational management.",
		},
		{
			icon: "fa fa-building", // Real Estate and Property Management
			name: "Real Estate and Property Management",
			text: "Enhanced Property and Client Management. Real estate agencies and property management companies rely on our web development expertise for optimized property listings and client management systems. We create dynamic websites and applications that improve lead generation, sales processes, and client communication, driving growth in your business.",
		},
	];

	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<div id="testimonials">
			<motion.div
				ref={inViewRef}
				initial="hidden"
				animate={controls}
				variants={{
					hidden: { opacity: 0, y: 100 },
					visible: { opacity: 1, y: 0 },
				}}
				transition={{ delay: 0.2, duration: 0.9 }}
				className="container">
				<Typography
					className="ourServices"
					variant="h3"
					component="h2"
					style={{ textAlign: "center", paddingTop: "24px" }}>
					Industries We Serve
				</Typography>
				<div className="row">
					{testimonialsData.map((testimonial, index) => (
						<Card
							key={index}
							className="testimonial-card"
							sx={{ maxWidth: 345 }}>
							<CardContent className="cardc0ntent">
								<div className="testimonial-icon">
									<i className={testimonial.icon}></i>
								</div>
								<Typography
									className="testimonial-name"
									gutterBottom
									variant="h5"
									component="div">
									{testimonial.name}
								</Typography>
								<Typography
									className="testimonial-text"
									variant="body1"
									color="text.secondary">
									{testimonial.text}
								</Typography>
							</CardContent>
						</Card>
					))}
				</div>
			</motion.div>
		</div>
	);
};

export default Testimonials;
