import React from "react";
import { Card, CardContent, Typography, CardActions } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ScottK from "../../assets/images/ScottK.jpeg";
import "./aboutus.css";

const AboutUs = () => {
	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.125,
	});

	React.useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<motion.div
			className="wide-card"
			ref={inViewRef}
			initial="hidden"
			animate={controls}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
			transition={{ delay: 0.2, duration: 0.9 }}>
			<Card className="wide-card-content">
				<div className="card-inner">
					<CardContent className="card-content">
						<Typography
							className="card-title"
							gutterBottom
							variant="h3"
							component="div">
							What Makes Us Different
						</Typography>
						<Typography
							className="card-description"
							variant="body1"
							color="text.secondary">
							At Kleinberg Consulting, our decade-long experience
							sets us apart in delivering exceptional business
							solutions. Our team, spread across the US and
							Canada, comprises skilled developers and CRM experts
							who excel in crafting tailored strategies for CRM
							implementation and marketing automation. We have
							hands-on experience in a wide range of industries,
							allowing us to understand and address the unique
							challenges each sector faces. We focus on enhancing
							efficiency and achieving measurable results for our
							clients. Our commitment to innovation and excellence
							ensures that partnering with Kleinberg Consulting
							drives significant and sustainable growth for your
							business. Experience the difference our expertise
							can make.
						</Typography>
					</CardContent>
					<img
						className="card-img-aboutus"
						src={ScottK}
						alt="Scott Kleinberg, CEO of Kleinberg Consulting"
					/>
				</div>
				<CardActions></CardActions>
			</Card>
		</motion.div>
	);
};

export default AboutUs;
