import React, { useRef } from "react";
import "./webdevlandingpage.css"; // Import global styles
import About from "../../components/webdevcomponents/webdevabout/webdevabout";
import Features from "../../components/webdevcomponents/webdevfeatures/webdevfeatures";
import Header from "../../components/webdevcomponents/webdevheader/webdevheader";
import Testimonials from "../../components/webdevcomponents/webdevtestimonials/webdevtestimonials";
import Servicestwo from "../../components/webdevcomponents/webdevservices/webdevservices";
import Footer from "../../../src/pages/Footer";
import { Contact } from "../../components/webdevcomponents/webdevcontact/webdevcontact";
import { Navbar } from "../../../src/pages/Navbar";

const LandingPage = () => {
	const contactRef = useRef(null);

	const scrollToContact = () => {
		contactRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div>
			<Navbar onContactClick={scrollToContact} />
			<Header onContactClick={scrollToContact} />
			<section id="about">
				<About />
			</section>
			<section id="features">
				<Features />
			</section>
			<section id="services">
				<Servicestwo />
			</section>
			<section id="testimonials">
				<Testimonials />
			</section>
			<Contact ref={contactRef} />
			<Footer />
		</div>
	);
};

export default LandingPage;
