import React, { useRef } from "react";
import "./LandingPage.css"; // Import global styles
import About from "../about/about";
import Features from "../features/features";
import Header from "../header/header";
import Testimonials from "../testimonials/Testimonials";
import Servicestwo from "../servicestwo/servicestwo";
import Footer from "../../../src/pages/Footer";
import { Contact } from "../hubspotcontactus/hubspotcontactus";
import { Navbar } from "../../../src/pages/Navbar";

const LandingPage = () => {
	const contactRef = useRef(null);

	const scrollToContact = () => {
		contactRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div>
			<Navbar onContactClick={scrollToContact} />
			<Header onContactClick={scrollToContact} />
			<section id="about">
				<About />
			</section>
			<section id="features">
				<Features />
			</section>
			<section id="services">
				<Servicestwo />
			</section>
			<section id="testimonials">
				<Testimonials />
			</section>
			<Contact ref={contactRef} />
			<Footer />
		</div>
	);
};

export default LandingPage;
