import React from "react";
import "./Services.css";
import { default as CRM } from "../../assets/icons/CRM_Consulting.png";
import { default as API } from "../../assets/icons/API.png";
import { default as Webdev } from "../../assets/icons/Web_Development_2.png";
import { default as QA } from "../../assets/icons/QA.png";
import { default as Marketing } from "../../assets/icons/Digital_Marketing.png";
const Service = () => {
	return (
		<div id="services" className="service">
			<div className="serviceContainer">
				<div className="service-nested quality">
					<div className="ser-imgDiv">
						<p className="serviceImgDiv">
							<img src={CRM} alt="quality" width="100%" />
						</p>
					</div>
					<div className="lines">
						<h2 className="serviceTitle">CRM Consulting</h2>
						<p className="service-text">
							Maximize your business potential with our tailored
							CRM consulting. From implementation to optimization,
							we guide you every step for streamlined processes,
							improved customer relationships, and accelerated
							growth. Partner with us to unlock CRM’s power and
							propel your business forward.
						</p>
					</div>
				</div>
				<div className="service-nested expert">
					<div className="ser-imgDiv ">
						<p className="serviceImgDiv">
							<img src={API} alt="experts" width="100%" />
						</p>
					</div>
					<div className="lines">
						<h2 className="serviceTitle">API Integrations</h2>
						<p className="service-text">
							Transform your business with our tailored API
							integration services. Our experts bridge system gaps
							for seamless data exchange and enhanced
							functionality. From setup to ongoing support, we
							optimize your digital ecosystem, empowering
							operations and unlocking new possibilities.
						</p>
					</div>
				</div>
				<div className="service-nested quality">
					<div className="ser-imgDiv">
						<p className="serviceImgDiv">
							<img src={Webdev} alt="quality" width="100%" />
						</p>
					</div>
					<div className="lines">
						<h2 className="serviceTitle">Web Development</h2>
						<p className="service-text">
							Unlock the full potential of your online presence
							with our expert Web Development solutions. Our team
							specializes in crafting bespoke websites that
							captivate audiences and drive tangible results. From
							sleek designs to seamless functionality, we turn
							your digital aspirations into reality.
						</p>
					</div>
				</div>

				<div className="service-nested hours">
					<div className="ser-imgDiv">
						<p className="serviceImgDiv">
							<img
								className="hourImg"
								src={QA}
								alt="quality"
								width="100%"
							/>
						</p>
					</div>
					<div className="lines">
						<h2 className="serviceTitle">
							Quality Assurance Services
						</h2>
						<p className="service-text">
							Elevate your project’s quality with our expert
							freelance QA services. Our meticulous testing
							guarantees top standards pre-launch, ensuring a
							polished product. With expertise across industries
							and technologies, we offer tailored solutions to fit
							your needs, enhancing user satisfaction and
							minimizing errors.
						</p>
					</div>
				</div>

				<div className="service-nested quality">
					<div className="ser-imgDiv">
						<p className="serviceImgDiv">
							<img src={Marketing} alt="quality" width="100%" />
						</p>
					</div>
					<div className="lines">
						<h2 className="serviceTitle">Digital Marketing</h2>
						<p className="service-text">
							Elevate your online presence with our dynamic
							Digital Marketing solutions. Our seasoned
							professionals craft customized strategies to amplify
							your brand's reach and engagement. From targeted
							campaigns to comprehensive analytics, we synchronize
							every facet of your digital footprint, driving
							growth and maximizing ROI.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Service;
