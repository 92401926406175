import React, { useEffect, useState } from "react";
import "./Navbar.css";

import { Link, NavLink } from "react-router-dom";

export const Navbar = ({ onContactClick }) => {
	const [sticky, setSticky] = useState(false);
	const [open, setOpen] = useState(false);

	function menuOpen() {
		setOpen(!open);
	}

	useEffect(() => {
		const handleScroll = () => {
			setSticky(window.scrollY > 200);
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	function scrollTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}

	function scrollToServices() {
		const servicesSection = document.getElementById("services");
		if (servicesSection) {
			window.scrollTo({
				top: servicesSection.offsetTop,
				behavior: "smooth",
			});
		}
	}

	return (
		<div>
			<header className={`header sticky`}>
				<nav className="Navcontainer">
					<Link to="/">
						<div className="logo" onClick={scrollTop}>
							<span className="logo-span">
								Kleinberg Consulting
							</span>
						</div>
					</Link>
					<div className="social">
						<p className="fa-envelopeP">
							<i className="fa-solid fa-envelope"></i>
							hello@kleinbergconsulting.com
						</p>
						<a className="fa-phoneP" href="tel:+3303538811">
							<i className="fa-solid fa-phone"></i>
							<span className="phonenumber">+330-353-8811</span>
						</a>
					</div>
					<ul className="desktopMenu">
						<li>
							<NavLink activeclassname="active" to="/">
								Home
							</NavLink>
						</li>
						<li>
							<p
								className="service-nav"
								onClick={scrollToServices}>
								Services
							</p>
						</li>
						<li>
							<p className="service-nav" onClick={onContactClick}>
								Contact
							</p>
						</li>
					</ul>
					<div
						onClick={menuOpen}
						className={`${
							open ? "hamburger active" : "hamburger"
						}`}>
						<span className="bar"></span>
						<span className="bar"></span>
						<span className="bar"></span>
					</div>
				</nav>
			</header>
			<div className={`${open ? "mobileMenu active" : "mobileMenu"}`}>
				<ul className="menuUl">
					<li
						onClick={() => {
							menuOpen();
							scrollTop();
						}}>
						<p className="hamburgertext">Home</p>
					</li>
					<li
						onClick={() => {
							menuOpen();
							scrollToServices();
						}}>
						<p className="hamburgertext">Services</p>
					</li>
					<li
						onClick={() => {
							menuOpen();
							onContactClick();
						}}>
						<p className="hamburgertext">Contact</p>
					</li>
				</ul>
			</div>
		</div>
	);
};
