import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import "./Home.css";
import Service from "../components/services/Service";
import Footer from "./Footer";
import { Contact } from "./Contact";
import IntroSlider from "../components/introSlider/IntroSlider";
import { Helmet } from "react-helmet-async";
import ScrollToTopButton from "../components/scrollTo/ScrollTop";
import MultiActionAreaCard from "../components/cards/cards";
import ReactGA from "react-ga";
import LeadCapture from "../components/leadCapture/LeadCapture";
import { Navbar } from "./Navbar";
import ZohoChatWidget from "../components/zohoChatWidget/ZohoChatWidget";
import AboutUs from "../components/aboutus/aboutus";

const Home = () => {
	const contactRef = useRef(null);
	const cardRefs = [useRef(null), useRef(null), useRef(null)];

	const scrollToContact = () => {
		contactRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const scrollToCard = (index) => {
		cardRefs[index].current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<>
			<Helmet>
				<title>Kleinberg Consulting</title>
				<meta
					name="description"
					content="Welcome to Kleinberg Consulting's home page"
				/>
				<meta
					name="keywords"
					content="consulting, business, services, Kleinberg Consulting"
				/>
				<meta name="author" content="Kleinberg Consulting" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0"
				/>
				<link rel="canonical" href="/" />
			</Helmet>
			<motion.div
				className="home"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}>
				<Navbar onContactClick={scrollToContact} />
				<div className="leadcapture">
					<LeadCapture />
				</div>
				<div className="sliderDesktopMode">
					<IntroSlider onContactClick={scrollToContact} />
				</div>
				<div className="landingMobileMode"></div>

				<Service />
				<AboutUs />
				<h2
					className="ourServices"
					style={{ textAlign: "center", paddingTop: "24px" }}>
					Our Services
				</h2>
				<MultiActionAreaCard
					onContactClick={scrollToContact}
					cardRefs={cardRefs}
				/>
				<ZohoChatWidget />
				<Contact ref={contactRef} />
				<ScrollToTopButton />
				<Footer scrollToCard={scrollToCard} />
			</motion.div>
		</>
	);
};

export default Home;
