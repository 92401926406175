import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import badgeImage from "../../assets/images/provider-badge-color_1.png";
import toast, { Toaster } from "react-hot-toast";
import "./header.css";

const Header = ({ onContactClick }) => {
	const [contactData, setContactData] = useState({});
	const [errorMsg, setErrorMsg] = useState();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setContactData({
			...contactData,
			[name]: value,
		});

		if (name === "Phone") {
			setContactData({
				...contactData,
				Phone: value.replace(/\D/g, ""),
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { Last_Name, Phone, Email, First_Name, Hubspot_Interested_In } =
			contactData;

		if (
			!Last_Name ||
			!Phone ||
			!Email ||
			!First_Name ||
			!Hubspot_Interested_In
		) {
			toast.error("All fields are required.");
			setErrorMsg("All fields are required.");
			return;
		}

		try {
			const response = await axios.post(
				`https://y-iota-blue.vercel.app/api/leads/addCompanyToCRM`,
				{
					Last_Name,
					Phone,
					Email,
					First_Name,
					Hubspot_Interested_In,
				}
			);

			if (response.status === 201) {
				toast.success("Your message has been sent successfully!");
				setContactData({}); // Clear form after success toast
				setErrorMsg(null);
			} else {
				throw new Error("Failed to create lead");
			}
		} catch (error) {
			toast.error("Failed to send your message. Please try again.");
			setErrorMsg("Failed to post data to server");
			console.error("Error posting data to server:", error);
		}
	};

	const options = [
		{ value: "PCAO", label: "Please choose an option" },
		{ value: "Hubspot CRM", label: "Hubspot CRM" },
		{ value: "Hubspot Marketing Hub", label: "Hubspot Marketing Hub" },
		{ value: "Hubspot Sales Hub", label: "Hubspot Sales Hub" },
		{ value: "Hubspot Service Hub", label: "Hubspot Service Hub" },
		{ value: "Other", label: "Other" },
	];

	return (
		<div id="landing-header" className="hero">
			<Toaster position="top-right" reverseOrder={false} />
			<div className="hero__content">
				<h1 className="hero__title">Customized HubSpot Solutions</h1>
				<h2 className="hero__subtitle">
					Tailor-made HubSpot strategies to elevate your business.
				</h2>
				<div className="hero__button-container">
					<button className="hero__button" onClick={onContactClick}>
						<i className="hero__icon fa-solid fa-hand-pointer"></i>{" "}
						LEARN MORE
					</button>
					<img
						src={badgeImage}
						alt="Provider Badge"
						className="hero__badge"
					/>
				</div>
			</div>
			<div id="contact" className="hero-contact">
				<div className="form-hero">
					<form
						onSubmit={handleSubmit}
						encType="multipart/form-data"
						autoComplete="off">
						<>
							{errorMsg && (
								<div className="alert alert-danger">
									{errorMsg}
								</div>
							)}

							<p className="contact-form-header">
								How Can We Help?
							</p>
							<div className="form-group1">
								<div className="hero-input-fields">
									<p className="danny">First Name:</p>
									<input
										name="First_Name"
										type="text"
										className="form-control"
										placeholder="First Name"
										value={contactData.First_Name || ""}
										onChange={handleChange}
									/>
								</div>
								<div className="hero-input-fields">
									<p className="danny">Last Name:</p>
									<input
										name="Last_Name"
										type="text"
										className="form-control"
										placeholder="Last Name"
										value={contactData.Last_Name || ""}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-group2">
								<div className="hero-input-fields">
									<p className="danny">Email:</p>
									<input
										name="Email"
										type="Email"
										className="form-control"
										placeholder="Email"
										value={contactData.Email || ""}
										onChange={handleChange}
									/>
								</div>
								<div className="hero-input-fields">
									<p className="danny">Phone:</p>
									<input
										maxLength={10}
										name="Phone"
										type="text"
										className="form-control"
										placeholder="Phone"
										value={contactData.Phone || ""}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-group3">
								<p className="danny">Interested in:</p>
								<select
									name="Hubspot_Interested_In"
									className="form-control form-input"
									value={
										contactData.Hubspot_Interested_In || ""
									}
									onChange={handleChange}>
									{options.map((option) => (
										<option
											key={option.value}
											value={option.value}>
											{option.label}
										</option>
									))}
								</select>
							</div>
							<div className="form-group4-parent">
								<button className="form-group4" role="button">
									Submit
								</button>
								<p className="disclaimer">
									You can unsubscribe at any time from
									communications we may send you. See our{" "}
									<NavLink to="/privacypolicy">
										Terms & Privacy
									</NavLink>{" "}
									notice for more information on how we
									process your personal data.
								</p>
							</div>
						</>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Header;
