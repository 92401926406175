import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./webdevservices.css"; // Import the custom CSS

const Servicestwo = () => {
	const servicesData = [
		{
			icon: "fa fa-paint-brush", // Creative Web Design
			name: "Creative Web Design",
			text: "Transform your online presence with creative web design. We craft visually captivating websites that reflect your brand’s personality, enhance user engagement, and deliver a standout digital experience across all devices.",
		},
		{
			icon: "fa fa-tachometer-alt", // Custom Dashboard Development
			name: "Custom Dashboard Development",
			text: "Elevate your data management with custom dashboards. We design and develop interactive dashboards that simplify data visualization, provide actionable insights, and streamline decision-making to support your business goals.",
		},
		{
			icon: "fa fa-code", // Web Application Development
			name: "Web Application Development",
			text: "Boost your operational efficiency with bespoke web applications. Our team builds dynamic, scalable applications tailored to your unique needs, automating processes and enhancing functionality to improve overall productivity.",
		},
		{
			icon: "fa fa-shopping-cart", // E-Commerce Development
			name: "E-Commerce Development",
			text: "Enhance your online store with custom e-commerce solutions. We create robust and secure e-commerce platforms designed to drive sales, optimize user experience, and support long-term customer loyalty.",
		},
		{
			icon: "fa fa-rocket", // Website Optimization
			name: "Website Optimization",
			text: "Revolutionize your website’s performance with our optimization services. We fine-tune site speed, enhance mobile responsiveness, and implement SEO best practices to improve visibility and boost conversion rates.",
		},
		{
			icon: "fa fa-sitemap", // Custom CMS Design
			name: "Custom CMS Design",
			text: "Create engaging websites and content management systems with our custom CMS design services. We design intuitive templates, integrate advanced features, and ensure your CMS aligns perfectly with your content strategy and goals.",
		},
		{
			icon: "fa fa-chart-bar", // Web Analytics & Reporting
			name: "Web Analytics & Reporting",
			text: "Unlock the full potential of your web data with our analytics and reporting solutions. We develop custom reports and interactive dashboards to consolidate data, track key metrics, and provide insights that drive strategic decisions.",
		},
		{
			icon: "fa fa-search", // SEO & Marketing Integration
			name: "SEO & Marketing Integration",
			text: "Maximize your online impact with integrated SEO and marketing solutions. We enhance your website’s search engine visibility, implement effective marketing strategies, and synchronize CRM systems to boost engagement and drive results.",
		},
	];

	return (
		<div id="services" className="text-center">
			<div className="container">
				<Typography className="ourServices" variant="h3" component="h2">
					Services We Offer
				</Typography>
				<div className="row">
					{servicesData.map((service, index) => (
						<div key={index} className="service-card-wrapper">
							<Card className="service-card">
								<div className="service-card-inner">
									<div className="service-card-front">
										<CardContent>
											<Typography
												variant="h4"
												component="div"
												className="service-icon">
												<i className={service.icon}></i>
											</Typography>
											<Typography
												variant="h5"
												component="h3">
												{service.name}
											</Typography>
										</CardContent>
									</div>
									<div className="service-card-back">
										<CardContent>
											<Typography
												variant="body1"
												component="p"
												className="service-description">
												{service.text}
											</Typography>
										</CardContent>
									</div>
								</div>
							</Card>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Servicestwo;
