import React, { useEffect, useState } from "react";
import "./Map.css";
import { default as mapIcon } from "../../assets/icons/map.png";
const Map = () => {
	const [fetchData, setFetchData] = useState(null);
	const [loading, setLoading] = useState(true);

	const map = async () => {
		try {
			const response = await fetch(
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81262.15368599011!2d-81.41804205370616!3d40.80768901449634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836c84fd29980a9%3A0x12cc9ba49ffa6f4b!2sCanton%2C%20OH%2C%20USA!5e0!3m2!1sen!2sca!4v1712861695232!5m2!1sen!2sca"
			);

			setFetchData(response);
			setLoading(false);
		} catch (error) {
			setLoading(true);
		}
	};

	useEffect(() => {
		map();
	}, []);
	return (
		<div className="map boxTwo">
			{loading ? (
				<div className="mapNotFound">
					<h4>OOPs live map not found</h4>
					<img src={mapIcon} alt="map" width="70%" />
				</div>
			) : (
				<iframe
					src={fetchData.url}
					style={{ border: 0 }}
					loading="lazy"
					title="google live map"
					referrerpolicy="no-referrer-when-downgrade"></iframe>
			)}
		</div>
	);
};

export default Map;
