import React, { useState, useEffect } from "react";
import "./LeadCapture.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { NavLink } from "react-router-dom";

const LeadCapture = ({ onContactClick }) => {
	const [contactData, setContactData] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState(false);
	const [zohoContactInfo, setZohoContactInfo] = useState(null);

	const isValidEmail = (Email) => {
		return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(Email);
	};

	const isValidMobile = (mobileno) => {
		return /^[6-9]\d{9}$/.test(mobileno);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setContactData({
			...contactData,
			[name]: value,
		});

		if (name === "Mobile") {
			setContactData({
				...contactData,
				Mobile: value.replace(/\D/g, ""),
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { Last_Name, Mobile, Email, Country, Interested_In } =
			contactData;

		if (!Last_Name || !Mobile || !Email || !Country || !Interested_In) {
			setErrorMsg("All fields are required.");
			setSuccessMsg(false);
			return;
		}

		try {
			const backendUrl = process.env.REACT_APP_BACKEND_URL;
			const response = await axios.post(
				`https://y-iota-blue.vercel.app/api/leads/addUserToLeads`,
				{
					Last_Name,
					Mobile,
					Email,
					Country,
					Interested_In,
				}
			);

			if (response.status === 201) {
				setSuccessMsg(true);
				setErrorMsg(null);
			} else {
				throw new Error("Failed to create lead");
			}
		} catch (error) {
			setErrorMsg("Failed to post data to server");
			console.error("Error posting data to server:", error);
		}
	};
	const options = [
		{ value: "PCAO", label: "Please choose an option" },
		{ value: "CRM Consulting", label: "CRM Consulting" },
		{ value: "API Intergrations", label: "API Intergrations" },
		{ value: "Web Development", label: "Web Development" },
		{ value: "Digital Marketing", label: "Digital Marketing" },
		{
			value: "Quality Assurance Services",
			label: "Quality Assurance Services",
		},
	];

	return (
		<div id="contact" className="lead-capture">
			<div className="form-hero">
				<form
					onSubmit={handleSubmit}
					encType="multipart/form-data"
					autoComplete="off">
					{!successMsg ? (
						<>
							{errorMsg && (
								<div className="alert alert-danger">
									{errorMsg}
								</div>
							)}

							<p className="contact-form-header-mobile">
								How can we help?
							</p>
							<div className="form-group1">
								<div className="hero-input-fields">
									<p className="danny">Name:</p>
									<input
										name="Last_Name"
										type="text"
										className="form-control"
										placeholder="Name"
										value={contactData.Last_Name || ""}
										onChange={handleChange}
									/>
								</div>
								<div className="hero-input-fields">
									<p className="danny">Mobile:</p>
									<input
										name="Mobile"
										type="text"
										maxLength={10}
										className="form-control"
										placeholder="Mobile"
										value={contactData.Mobile || ""}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-group2">
								<div className="hero-input-fields">
									<p className="danny">Email:</p>
									<input
										name="Email"
										type="Email"
										className="form-control"
										placeholder="Email"
										value={contactData.Email || ""}
										onChange={handleChange}
									/>
								</div>
								<div className="hero-input-fields">
									<p className="danny">Country:</p>
									<input
										name="Country"
										type="text"
										className="form-control"
										placeholder="Country"
										value={contactData.Country || ""}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-group3">
								<p className="danny">Interested in:</p>
								<select
									name="Interested_In"
									className="form-control form-input"
									value={contactData.Interested_In || ""}
									onChange={handleChange}>
									{options.map((option) => (
										<option
											key={option.value}
											value={option.value}>
											{option.label}
										</option>
									))}
								</select>
								{/* <input
											name="Interested_In"
											type="text"
											className="form-control"
											placeholder="Interested In"
											value={
												contactData.Interested_In || ""
											}
											onChange={handleChange}
										/> */}
							</div>
							<div className="form-group4-parent-mobile">
								<button class="form-group4 " role="button">
									Submit
								</button>
								<p className="disclaimer">
									You can unsubscribe at any time from
									communications we may send you. See our{" "}
									<NavLink> Terms & Privacy </NavLink>
									notice for more information on how we
									process your personal data.
								</p>
							</div>
						</>
					) : (
						<div className="show" id="sendmessage">
							Thank for your message. We will contact you soon.
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default LeadCapture;
